<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="notificationCount"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Notifications
        </h4>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Account Notification -->
      <b-link
        v-for="notification in limitedNotifications"
        :key="notification.subtitle + notification.tanggal + notification.jam"
      >
        <b-media>
          <template #aside>
            <b-avatar
              size="32"
              :variant="notification.type"
            >
              <feather-icon :icon="notification.icon" />
            </b-avatar>
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.title }}
            </span>
            <small class="font-weight-bolder float-right">
              {{ notification.tanggal }} {{ notification.jam  ? ' - '+notification.jam  : ''}}
            </small>
          </p>
          <small class="text-dark font">{{ notification.subtitle }}</small>
        </b-media>
      </b-link>
      <div class="text-center" v-if="this.notificationCount > this.maxItems">
        {{ this.notificationCount - this.maxItems }} more notification...
      </div>
    </vue-perfect-scrollbar>

    <!-- Notification Footer -->
    <li class="dropdown-menu-footer">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        :to="{ name: 'notifications' }"
      >Read All notifications</b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, BFormCheckbox,
} from 'bootstrap-vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import Ripple from 'vue-ripple-directive';

import axios from "axios";
import Helper from "@/api/helper";

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }
    return {
      perfectScrollbarSettings,
    }
  },
  data() {
    return {
      maxItems: 5,
      notifications: [
        // {
        //   title: 'Data Suspect',
        //   subtitle: 'Station Meteorologi Maimun Saleh - sinoptik',
        //   tanggal: "2022-01-12",
        //   jam: "12:00",
        //   type: 'warning',
        //   icon: 'AlertTriangleIcon',
        // },
      ],
    };
  },
  computed: {
    limitedNotifications() {
      return this.notifications
        .sort((a, b) => -(a.tanggal.localeCompare(b.tanggal)) || -(a.jam.localeCompare(b.jam)))
        .filter((a, i) => i < this.maxItems);
    },
    notificationCount() {
      return this.notifications.length;
    },
  },
  mounted() {
    this.getQCNotification();
  },
  methods: {
    // AN Disable temporary
    getQCNotification() {

      // let userLogin = JSON.parse(localStorage.getItem('userLogin'))
		  // let userType = userLogin.username
      // let ids = ""
      // let arrIds = null
      
      // if (userLogin.hasOwnProperty('groupIds')) {
      //   ids = userLogin.groupIds
      //   arrIds = ids.split(',')
      // }

      // const config = {
      //   mode: "cors",
      //   withCredentials: false,
      //   params: {
      //     type_name: "QcNotification",
      //     qc_flag: 1,
      //     _metadata: "id,form_type,jam_pengamatan,qc_flag,station_id,station_name,tanggal_pengamatan",
      //     _size:1000
      //   },
      //   headers: Helper.getHeader(),
      // };
      // const get_url = Helper.getBaseUrl() + "@aggregation_query";
      // axios
      //   .get(get_url, config)
      //   .then((response)=>{
      //     const { data } = response;
      //     this.notifications = [];
      //     data.forEach(row => {
      //       // const [station_id, form_type, tanggal_pengamatan] = row.id.substring("qcnotification_".length).split("_");
      //       let exist = arrIds ? arrIds.includes(""+row.station_id) : true
      //       if (exist) {
      //         this.notifications.push({
      //           station_id: row.station_id,
      //           station_name: row.station_name,
      //           form_type: row.form_type,
      //           tanggal: row.tanggal_pengamatan.split("T")[0],
      //           jam: row.jam_pengamatan,
      //           title: 'Data Suspect',
      //           subtitle: `${row.station_name} - ${row.form_type}`,
      //           type: 'warning',
      //           icon: 'AlertTriangleIcon',
      //         });
      //       }
      //     });
      //     this.notifications.filter(function(item){ 
      //         return arrIds.includes(item.station_id);
      //     });
      //     this.notifications = this.notifications.sort((a, b) => new Date(b.tanggal) - new Date(a.tanggal));
      //   })
      //   .catch(error=>{
      //     console.log(error);
      //   })
    },
  },
}
</script>

<style>

</style>
